// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { inline } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';

export const Label = styled.label`
    ${font.sm};
    line-height: 24px;
    display: block;
    cursor: pointer;
    ${(props) =>
        props.disabled &&
        `
        color: ${colors['$hpx-grey-500']};
    `}
`;

export const HiddenCheckbox = styled.input`
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
`;

export const StyledCheckbox = styled.span`
    ${inline._1x};
    font-size: 16px;
    line-height: 1;
    background-color: ${colors['hpx-white']};
    color: ${colors['hpx-white']};
    border: 1px solid ${colors['$hpx-grey-500']};
    border-radius: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    text-align: center;

    ${(props) =>
        props.checked &&
        `
        background-color: ${colors['$hpx-teal-500']};
    `}


    ${(props) =>
        props.disabled &&
        `
        background-color: ${colors['$hpx-grey-100']};
        border-color: ${colors['$hpx-grey-100']};
    `}

    ${(props) =>
        props.extraWide && `
        margin-right: 16px;
    `}

    ${HiddenCheckbox}:focus + & {
        outline: -webkit-focus-ring-color auto 1px;
        outline-offset: 5px;
    }
`;
