// @ts-nocheck
/* eslint-enable */
import styled, { css } from "styled-components";
import { colors } from "app/shared/styles/_colors";
import { textOverflow } from "app/shared/styles/_mixins";
import { font } from "app/shared/styles/_fontsize";
import TextBase from 'app/shared/core/Text';

export const InputRequiredMark = styled.span`
    color: ${colors['$hpx-red-400']};
`;
export const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Input = styled.input`
    ${font.md};
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    flex-grow: 1;
    letter-spacing: 0.5px;
    padding: 8px;
    color: ${colors['$hpx-blue-600']};
    height: 34px;

    // Placeholder fallbacks
    &::placeholder {
        color: ${colors['$hpx-grey-500']};
        text-overflow: ellipsis;
    }
    &::-webkit-input-placeholder {
        color: ${colors['$hpx-grey-500']};
        text-overflow: ellipsis;
    }
    &:-moz-placeholder {
        color: ${colors['$hpx-grey-500']};
        text-overflow: ellipsis;
    }
    &::-moz-placeholder {
        color: ${colors['$hpx-grey-500']};
        text-overflow: ellipsis;
    }
    &:-ms-input-placeholder {
        color: ${colors['$hpx-grey-500']};
        text-overflow: ellipsis;
    }

    ${(props) => props.inputButtonGroupPosition === 'after' || props.inputButtonGroupPosition === 'both' && `
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-right: 1px solid ${colors['$hpx-white']};
    `}

    ${(props) => props.inputButtonGroupPosition === 'before' || props.inputButtonGroupPosition === 'both' && `
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-left: 1px solid ${colors['$hpx-white']};
    `}

    ${(props) => props.centerText && `
        text-align: center;
    `}

    ${(props) => props.disabled && `
        background-color: ${colors['$hpx-grey-100']};
        border: 1px solid ${colors['$hpx-grey-500']} !important;
        color: ${colors['$hpx-grey-600']};
    `}

    ${(props) => props.borderRadius && `
        border-radius: 4px;
    `}

    ${(props) => props.border && `
        border-radius: 4px;
        border: 1px solid ${colors['$hpx-grey-500']};
        &:hover {
            outline: none;
            border: 1px solid ${colors['$hpx-teal-500']};
        }
    `}

    ${(props) => !props.border && `
        border: none;
        outline: none;
    `}

    ${(props) => props.hpxStyle === 'error' && `
        border: 1px solid ${colors['$hpx-red-400']} !important;
    `}

    ${(props) => props.fixedSize && `
        resize: none;
    `}

    ${(props) => props.width === 'full' && `
        width: 100%;
        border-bottom: 1px solid ${colors['$hpx-grey-100']};
    `}

    ${(props) => props.inactive && `
        color: ${colors['$hpx-grey-600']};
    `}

    ${(props) => props.size === 'lg' && `
        ${font.md};
        height: 48px;
        padding: 13px 16px;
    `}

    ${(props) => props.size === 'xl' && `
        ${font.md};
        height: 100%;
        padding: 13px 16px;
    `}

    ${(props) => props.paddingRight === 'lg' && `
        padding-right: 90px;
    `}

    ${(props) => props.paddingRight === 'md' && `
        padding-right: 50px;
    `}

    ${(props) => props.type === 'textarea' && `
        height: 56px;
        resize: vertical;
    `}

    ${(props) => !props.isTextArea && css`
        ${textOverflow};
    `}
`;

export const InputRight = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    cursor: pointer;
`;

export const InputClear = styled.button`
    height: 100%;
    width: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const InputCancel = styled.button`
    height: 100%;
    width: 45px;
    padding-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const InputCancelText = styled(TextBase)`
    color: ${colors['$hpx-grey-600']};
    font-weight: bold;
`;