// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import IconArrowLeft from 'images/icons/arrow-left-dark-grey.svg';
import Linker from 'app/shared/modules/Linker';
import Text from 'app/shared/core/Text';
import './style.scss';

const StyledDarkText = styled(Text)`
    color: ${colors['$hpx-grey-600']};
`;
class PromptBreadcrumb extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="PromptBreadcrumb">
                <Linker linkType="accent" useButton onClick={this.props.onBackClick}>
                    <img className="PromptBreadcrumb-arrow" height="12px" src={IconArrowLeft} width="12px" alt="" />
                    <StyledDarkText size="sm">
                        Go back
                    </StyledDarkText>
                </Linker>
            </div>
        );
    }
}

export default connect()(PromptBreadcrumb);
