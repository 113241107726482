// @ts-nocheck
/* eslint-enable */
import React from 'react';

import * as S from './styles';

import IconCheckmarkWhite from 'images/icons/checkmark-white.svg';
import Text from 'app/shared/core/Text';
import ValueConstants from 'app/shared/constants/ValueConstants';

const Checkbox = ({
    checked,
    className,
    disabled = false,
    extraWide = false,
    label = '',
    onChange,
    textSize = 'sm',
    id = null // Must be unique. Will lead to unexpected behavior if 2 checkboxes on a page share an id.
}) => {
    const onKeyDown = (e) => {
        const { keyCode } = e;
        if (keyCode === ValueConstants.ENTER_KEYCODE) {
            onChange(e);
        }
    };

    return (
        <S.Label className={className} disabled={disabled} htmlFor={id}>
            <S.HiddenCheckbox
                id={id}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                onKeyDown={onKeyDown}
                aria-label={label}
            />
            <S.StyledCheckbox checked={checked} disabled={disabled} extraWide={extraWide} hasLabel={Boolean(label)}>
                {checked && <img src={IconCheckmarkWhite} height="14px" width="14px" alt="" />}
            </S.StyledCheckbox>
            <Text size={textSize}>{label}</Text>
        </S.Label>
    );
};

export default Checkbox;
